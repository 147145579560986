.close {
    font-size: 22px;
    color: #858585;
    text-decoration: none;
    width: fit-content;
    height: fit-content;
    line-height: 1;
    cursor: pointer;
  z-index: 9;
    &:hover {
      color: #7a7a7a;
    }
    &:active {
      color: black;
      opacity: 0.3;
    }
  }