.fifthStep {
  padding: 80px;
  height: 100%;
  padding-bottom: 0;
  display: flex;
  flex-direction: column;

  input {
    z-index: 999;
  }

  &__content {
    margin-bottom: 50px;
    display: flex;
    width: 100%;
    margin-left: 50px;
    height: 100%;
    flex: 1;
    overflow: hidden;
    &__withScroll{
      overflow: auto;
      padding-right: 50px;
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */
      &::-webkit-scrollbar {
        display: none;
      }
    }

    nav {
      max-width: 315px;
      width: 100%;
      margin-right: 80px;

      ul {
        list-style: none;

        li {
          margin-bottom: 20px;
          cursor: pointer;
        }
      }
    }

    &__top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 18px 0;
      border-bottom: 1px solid #CDD4D8;
      font-size: 20px;
      font-weight: 400;
      color: #858585;

      .print {
        font-size: 18px;
        font-weight: 400;
        color: #A60029;
        cursor: pointer;
      }

      div {
        display: flex;
        align-items: center;
        gap: 10px
      }
    }
    &__bottom{
      display: flex;
      flex-direction: column;
      gap:20px;

      ul{
        line-height: 28px;
        margin-bottom:10px;
        li{
          margin:5px 0;
          text-align: justify;
        }
      }
      .redText{
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        color:rgba(166, 0, 41, 1);
        margin: 10px 0;
      }
    }

    &__info {
      max-width: 964px;
      width: 100%;
      margin-top: 24px;
      
    

      div {
        -ms-overflow-style: none !important; /* IE and Edge */
        scrollbar-width: none !important; /* Firefox */
        &::-webkit-scrollbar {
          display: none !important;
        }
      }
    }

    &__checkboxes {
      margin-top: 40px;
      display: flex;
      flex-direction: column;
      gap: 25px;
      justify-content: center;
      text-align: justify;

      .containerCheck {
        position: relative;
        padding-left: 35px;
        margin-bottom: 12px;
        cursor: pointer;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
      }

      /* Hide the browser's default checkbox */
      .containerCheck input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
      }

      /* Create a custom checkbox */
      .checkmark {
        position: absolute;
        top: 3px;
        left: 0;
        height: 18px;
        width: 18px;
        background-color: #eee;
        border: 2px solid #2B3B57;
        border-radius: 4px;
      }

      .containerCheck .checkmark:after {
        left: 4px;
        top: 0.7px;
        width: 3px;
        height: 8px;
        border: solid white;
        border-width: 0 3px 3px 0;
        border-radius: 2px;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }

      /* When the checkbox is checked, add a blue background */
      .containerCheck input:checked ~ .checkmark {
        background-color: #2B3B57;
      }

      /* Create the checkmark/indicator (hidden when not checked) */
      .checkmark:after {
        content: "";
        position: absolute;
        display: none;
      }

      /* Show the checkmark when checked */
      .containerCheck input:checked ~ .checkmark:after {
        display: block;
      }

    }

  }
}

@media only screen and (max-width: 1000px){
  .fifthStep{
    &__content{
      margin-left: 0;
      nav{
        display: none;
      }
    }
  }
}
@media only screen and (max-width: 750px){
  .fifthStep{
    padding: 30px;
    &__content{
      margin-left: 0;
      margin-bottom: 20px;
      nav{
        display: none;
      }
      &__withScroll{
        padding-right: 10px;
      }
      &__top{
        font-size: 14px;
      }
    }
  }
}
