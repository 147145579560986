.about{
    display: flex;
    gap:60px;
    margin-bottom: 50px;
    padding: 0 15px;

    &__img{
        width: 50%;
        img{
            width: 100%;
            object-fit: cover;
        }
    }
    &__info{
        &__title{
            font-size: 64px;
            line-height: 48px;
            font-weight: 400;
            color:#202020;
            letter-spacing: -2px;
            margin-bottom: 40px;
        }
        &__history{
            margin-bottom: 30px;
            .history{
                display: flex;
                align-items: center;
                gap:12px;
                margin-bottom: 12px;
                &__subtitle{
                    font-size: 24px;
                    font-weight: 500;
                    line-height: 32px;
                    color:#2C4D86;
                }
                &__minusIcon{
                    position: relative;
                    width: 34px;
                    height: 34px;
                    cursor: pointer;
                    img{
                        width: 100%;
                        height: 100%;
                    }
                    &::before{
                        content:"";
                        left: 16px;
                        top: 8px;
                        width: 2px;
                        height: 19px;
                        transition:all 200ms;
                        background: #2C4D86;
                        position: absolute;
                        z-index: 222;
                        border-radius: 5px;
                    }
                    &.expanded{
                        &:before{
                            height:0;
                        }
                    }
                }
            }
            .historyText{
                padding-left: 47px;
                max-width: 460px;
                animation: slidein 300ms;
                font-size: 18px;
                line-height: 26px;
                font-weight: 400;
                color:#333333;
                letter-spacing: -1px;

            }
        }
    }
}

@keyframes slidein {
    0% {
      opacity:0;
    }
    30% {
      opacity:0.5;
    }
    100% {
      opacity:1;
    }
  }

  @media only screen and (max-width:750px){
    .about{
        &__img{
            display: none;
        }
        &__info{
            &__title{
                font-size: 48px;
                line-height: 48px;
            }
            &__history{
                .history{
                    &__subtitle{
                        font-size: 18px;
                    }
                    &__minusIcon{
                        width: 24px;
                        height: 24px;
                        &::before{
                            content:"";
                            left: 18px;
                            top:10px;
                            width: 2px;
                            height: 19px;
                            transition:all 200ms;
                            background: #2C4D86;
                            position: absolute;
                            z-index: 222;
                            border-radius: 5px;
                        }
                        &.expanded{
                            &:before{
                                height:0;
                            }
                        }
                    }
                }
                .historyText{
                    padding-left: 47px;
                    max-width: 460px;
                    animation: slidein 300ms;
                    font-size: 18px;
                    line-height: 26px;
                    font-weight: 400;
                    color:#333333;
                    letter-spacing: -1px;
    
                }
            }
        }
    }
  }

  
  @media only screen and (max-width:500px){
    .about{
        &__info{
            &__title{
                padding-left: 15px;
                font-size: 20px;
                font-weight: 500;
                letter-spacing: 0.1px;
                margin-bottom: 10px;
                
            }
            &__history{
                .history{
                    &__minusIcon{
                        width: 24px;
                        height: 24px;
                        &::before{
                            left: 11px;
                            top: 6px;
                            height: 12px;
                        }
                        &.expanded{
                            &:before{
                                height:0;
                            }
                        }
                    }
                }
                .historyText{
                    padding-left: 40px;
                    font-size: 16px;
    
                }
            }
        }
    }
  }