.cancelReason{
    padding: 50px 60px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap:40px;

    &__title{
        font-size: 20px;
        font-weight: 400;
        color:#202020;
    }
    &__subtitle{
        font-size: 18px;
        font-weight: 500;
        color:#A60029;
    }
    &__options{
        &__item{
            padding: 10px 0;
            display: flex;
            gap:10px
        }
    }
    &__input{
        p{
            margin-bottom: 10px;
        }
        input{
            padding: 12px;
            border: 1px solid rgba(169, 181, 188, 1);
            border-radius: 8px;
            outline: none;
        }
    }

    .radioContainer{
        display: block;
        position: relative;
        padding-left: 25px;
        cursor: pointer;
        font-size: 22px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        font-weight: 400;
        font-size: 16px;

        input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
        }

        .checkmark {
            position: absolute;
            top: 13px;
            left: 0;
            height: 18px;
            width: 18px;
            background-color: #eee;
            border-radius: 50%;
            border: 1.5px solid #A9B5BC;


            &::after{
                content: "";
                position: absolute;
                display: none;
            }
        }
        /* When the radio button is checked, add a blue background */
         input:checked ~ .checkmark {
            background: #FFFFFF;
        }

        input:checked ~ .checkmark::after {
            display: block;
        }
 
        /* Style the indicator (dot/circle) */
         .checkmark::after {
            top: 3px;
            left: 3px;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background: #2B3B57;
          }
    }
}

@media only screen and (max-width: 750px) {
    .cancelReason{
        padding: 20px 30px;
        gap:20px;
    }
}
@media only screen and (max-width: 450px) {
    .cancelReason{
       input[type=submit]{
           align-self: center;
       }
        &__input{
            align-self: center;
            text-align: center;
        }
    }
}
