.stepsWrapper{
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
  max-width: 1440px;
}
.stepDivider{
  height: 0px;
  flex: 1;
  margin: 0px 9px;
  border: 1px dashed;
  color: #CDD4D8;
}
.stepDivider.passedStep,
.stepDivider.currentStep{
    border-style: solid;
}
.stepNumber{
  color: #ffffff;
}
.stepPointLabel{
  font-weight: 600;
  font-size: 16px;
}
.stepPoint{
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #CDD4D8;
  background-color: #CDD4D8;
}
.stepPoint.passedStep .stepNumber{
  visibility: hidden;
}
.currentStep{
  color: #2C4D86;
  background-color: #2C4D86;
}
.stepPoint.passedStep{
  background: #009F13 url("../img/stepCheck.svg") no-repeat center;
}
span.stepPointLabel {
  margin-top: 12px;
}
.passedStep{
  color: #009F13;
}

@media only screen and (max-width:500px){
  .stepPointLabel{
    font-size: 10px;
  }
  .stepsWrapper{
    width: 120%;
    padding-right: 25px;
    padding-left: 15px;
  }
}