.advantages{
    width: 100%;
    margin-top: 80px;
    &__title{
        font-size: 64px;
        line-height: 88px;
        font-weight: 400;
        margin-bottom:55px;
    }
    &__content{
        display: flex;
        justify-content: space-between;
        margin-bottom: 110px;
    }
}

@media only screen and (max-width:1000px){
    .advantages{
        &__title{
            font-size: 48px;
            text-align: center;
        }
        &__content{
            flex-direction: column;
            align-items: center;
            gap: 60px;
        }
    }
}
@media only screen and (max-width:600px){
    .advantages{
        &__title{
            font-size: 20px;
            font-weight: 500;
            line-height: 48px;
            margin-bottom: 10px;
            text-align: start;
            padding-left: 30px;
        }
        &__content{
            flex-direction: column;
            align-items: center;
            gap: 60px;
            margin-bottom: 80px;
        }
    }
}