@import "./assets/scss/vars.scss";

*{
    margin: 0;
    box-sizing: border-box;
    scroll-behavior: smooth !important;
  }
  body{
    font-family: $fontRegular;
  }

.hidden{
  display: none;
}
.container{
    max-width: 1320px;
    width: 100%;
    margin: auto;
}
.row{
    display: flex;
}

.col6{
    width: 50%;
}
.col4{
    width: 33%;
}
.col3{
    width: 25%;
}
.show_sm{
  display: none ;
}
.profileEmpthy{
  min-width: 860px;
}
.termsActive{
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #A60029 !important;
    position: relative;
    &::after{
        content: "";
        position: absolute;
        right: 0;
        transform: translateY(-50%);
        top:50%;
        height: 24px;
        width: 2px;
        background-color: #A60029;
        border-radius: 12px;
    }
}

.overflow--hidden{
    overflow: hidden !important
}
.overflow--scroll{
  overflow: scroll;
}
.inputAlert{
  color: #A60029;
  font-size: 14px;
  font-weight: 500;
}
.flex-center{
  display: flex;
  justify-content: center;
  align-items: center;
}

//popup
.popup {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 999999;
    overflow: auto;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0.3s, opacity 0.3s;
    display: flex;
    &.active {
      visibility: visible;
      opacity: 1;
    }
    .hideArrow{
      transition: 1.5s;
      display: none;
    }
    &__downArrow{
      z-index: 3;
      position: fixed;
      bottom:110px;
      right: 300px;
      width:60px;
      height: 60px;
      background-color: #EAEDF3;
      border: 1px solid #FFFFFF;
      border-radius: 50%;
      color:#4F5C74;
      font-size: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      box-shadow: 0px 4px 14px rgba(0,0,0,0.15);
      .hide{
        display: none;
      } 
    }
    &__background {
      background-color: rgb(0, 0, 0,.2);
      position: fixed;
      width: 100%;
      height: 100%;
      cursor: pointer;
      top: 0;
      left: 0;
    }
    &__container {
      margin: auto;
      width: 100%;
      max-width: 966px;
      min-width: auto;
      min-height: 570px;
      position: relative;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background-color: white;
      &--lg{
        max-width: 1250px;
      }
      &--full{
        max-width: 100% !important;
        height: 100% !important;
      }
      &--sm{
        max-width: 551px;
        min-height: 455px;
        border-radius: 8px;
        max-height: 95vh;
      }
      .closeLayer {
        height: 100%;
        width: 100%;
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        cursor: pointer;
      }
      &__info {
        width: 100%;
        min-height: 570px;
        height: 100%;
        border-radius: 20px;
        background-repeat: no-repeat;
        background-size: cover;
        position: relative;
        z-index: 2;
        &--full{
          display: flex;
          justify-content: center;
        }
        &--sm{
          min-height: 455px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .close {
          position: absolute;
          right: 24px;
          top: 24px;
        }
      }
    }
  }
.popupBottom{
  display: flex;
  justify-content: center;
  margin: 60px 0;
  &__content{
    max-width: 1072px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  
  &__text{
    display: flex;
    align-items: center;
    gap:10px;
    max-width: 630px;
    font-size: 16px;
    font-weight: 600;
    color:#202020;
    a{
      color:#A60029;
      font-size: 14px;
    }

  }
  &__buttons{
    display: flex;
    gap:14px;
  }
}
}
.forgotPass{
  display: flex;
  justify-content: center;
  & > div{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 50px;
  }
}
  .formInput {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    position: relative;
    max-width: 240px;
    width: 100%;
    font-family: $fontRegular ;
    &--lg{
        max-width: 388px;
    }
    &__label {
      font-size: 14px;
      line-height: 22px;
      margin-bottom: 4px;
    }
    &__input {
      font-family: $fontRegular ;
      background: white;
      border: 1.5px solid #A9B5BC;
      border-radius: 8px;
      padding: 10px 8px;
      color:#333333;
      font-size: 16px;
      outline: none !important;
      width: 100%;
      &.error {
        border-color: #FF0000;
      }
       &--sm{
        width: 57px;
        margin-right: 14px;
       }
       &--md{
        width:156px;
        margin-left: 14px;
       }
    }
    .passwordIcon {
      background-image: url("../src/assets/img/popups/password-eye.svg.svg");
      width: 20px;
      height: 15px;
      position: absolute;
      top: 40px;
      right: 15px;
      cursor: pointer;
      z-index: 8;
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      &.show {
        background-image: url("../src/assets/img/popups/show-password-eye.svg");
      }
    }
  }

  .profile{
    display: flex;
    justify-content: center;
    min-height: 390px;
  }

  .inputError{
    color:#FF0000;
    font-size: 14px;
  }

//text classes
.main-title{
  font-size: 72px;
  line-height: 72px;
  font-weight: 500;
  letter-spacing: -2px;
  color:#333333;
}

.text-blue{
    font-size: 20px;
    line-height: 28px;
    font-weight: 500;
    color:#2B3B57;

    &--lg{
      line-height: 38px;
      font-size: 30px;
    }
}

.text-red{
  font-size: 16px;
  font-weight: 500;
  color:#A60029;
}

.text-blue-sm{
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  color: #2B3B57;
  &--underline{
    text-decoration: underline;
  }
  &--pointer{
    cursor: pointer;
  }
  &--xs{
    font-size: 12px;
  }
  &--light{
    color:#A8B6CD;
  }
  &--bl{
    color:#506B9B
  }
  &--gray{
    color:#4F5C74;
  }
  &--lightGray{
    color:#858585
  }
  
}

.text-gray-sm{
    font-size: 18px;
    line-height: 26px;
    color:#858585;
}

.text-gray-xs{
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color:#858585;
  &--md{
    font-size: 16px;
  }
}

.text-justify{
  text-align: justify;
}

.text-light{
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  color:#202020;
  &--semibold{
    font-weight: 500;
  }
  &--gray{
    color:#858585
  }
  &--reg{
    font-weight: 400;
  }
}
.text-lg{
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
  color:#333333
}

.text-sm{
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  color:#A8AFBA;

}
.subtitle{
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color:#333333;
  &--black{
    color:#202020;
  }
}


//input type range
input[type=range] {
  appearance: none;
  -webkit-appearance: none; /* Hides the slider so that custom slider can be made */
  width: 100%; /* Specific width is required for Firefox. */
  background: transparent; /* Otherwise white in Chrome */
}

input[type=range]:focus {
  outline: none; /* Removes the blue border. You should probably do some kind of focus styling for accessibility reasons though. */
}

input[type=range]::-ms-track {
  width: 100%;
  cursor: pointer;

  /* Hides the slider so custom styles can be added */
  background: transparent; 
  border-color: transparent;
  color: transparent;
  
}

input[type=range]::-webkit-slider-thumb{
  -webkit-appearance: none;
  border: 1.5px solid #EAEBEE;
  height: 12px;
  width: 12px;
  border-radius: 50%;
  background: #2B3B57;
  cursor: pointer;
  margin-top: -6px;
}
input[type=range]::-moz-range-thumb {
  -webkit-appearance: none;
  border: 1.5px solid #EAEBEE;
  height: 12px;
  width: 12px;
  border-radius: 50%;
  background: #2B3B57;
  cursor: pointer;
  margin-top: -6px;
}

input[type=range]::-webkit-slider-runnable-track, {
  width: 100%;
  height: 2px;
  cursor: pointer;
  background: #EAEBEE;
  border-radius: 4px;
  border: 0.2px solid #EAEBEE;
}
input[type=range]::-moz-range-track, {
  width: 100%;
  height: 2px;
  cursor: pointer;
  background: #EAEBEE;
  border-radius: 4px;
  border: 0.2px solid #EAEBEE;
}


.loanInfoTop{
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 20px 0;
  border-bottom: 1px solid #CDD4D8;
}

.loanInfoTitle{
  color: #a60029;
  margin-top: 30px;
  margin-bottom: 60px
}

//input range thumb animation
.blob {
  border-radius: 50%;
  height: 18px;
  width: 18px !important;
  border: 2px solid #EAEBEE;
  background-color: #2B3B57;
  margin-top: -7px;
  box-shadow: 0 0 0 0 rgba(43, 59, 87,1);
  transform: scale(1);
  animation: pulse 1s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(43, 59, 87,0.5);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 5px rgba(43, 59, 87,0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(43, 59, 87,0);
  }
}



@media only screen and (max-width:1200px){
  .main-title{
    font-size: 62px;
    text-align: center;
  }
  .loanInfoTop{
    flex-direction: column;
    align-items: flex-start;
  }
}
@media only screen and (max-width:1040px){
  .row{
    flex-direction: column;
    gap:20px
  }
  .col3,
  .col6{
    width: 100%;
  }
}
@media only screen and (max-width:1000px){
  .popup{
    &__container{
      max-width: 700px;
      &--lg {
        max-width: 720px;
        padding: 30px;
    }
    }
  }
  .text-lg{
    font-size: 22px;
  }
}

@media screen and (max-width: 1024px){
  .profileEmpthy{
    min-width: 500px;
  }
  .hide_sm{
    display: none;
  }
  .show_sm{
    display: initial;
    z-index: 5;
  }
  .text-blue--lg{
    font-size: 15px;
  }
}
@media only screen and (max-width:750px){
  .main-title{
    font-size: 30px;
    font-weight: 400;
    line-height: 22px;
  }
  .text-light{
    font-size: 12px;
    line-height: 16px;
  }
  .text-lg{
    font-size: 14px;
    line-height: 20px;
  }
  .text-blue{
    font-size: 14px;
    line-height: 22px;
  }
  .popup {
    &__container {

      max-width: 500px;

      &--lg {
        max-width: 500px;
        padding: 10px;
      }

      &--sm {
        min-height: 410px;
      }

      &__info {
        &--sm {
          min-height: 410px;
        }
      }
    }
    &__downArrow{
      z-index: 10;

    }
  }
  .popupBottom{
    &__content{
      flex-direction: column;
      gap:20px;
    }
  } }
@media only screen and (max-width:500px){
  .profileEmpthy {
    min-width: 330px;
  }
    .popup{
    &__container{
      max-width: 360px;
      &--lg {
        max-width: 360px;
      }
    }
    &__downArrow{
      width: 40px;
      height: 40px;

    }
  }
  .formInput{
    max-width: 290px;
  }
}
@media only screen and (max-width:390px){
  .popup{
    &__container{
      max-width: 330px;
      &--lg {
        max-width: 330px;
      }
    }
  }
}