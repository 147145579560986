.bannerFrame{
    backdrop-filter: blur(5px);
    border-radius: 22px;
    border: 1px solid rgba(94, 153, 153, 0.33);
    background: rgba(255, 255, 255, 0.8);
    &--leftTop{
        box-shadow: -10px 14px 20px rgba(112, 112, 112, 0.25);
        border-top-left-radius: 0 !important;
        padding: 16px 20px;
        position: absolute;
        right: 680px;
        top:190px;
    }
    &--rightTop{
        border-top-right-radius: 0 !important;
        padding: 16px 10px;
        position: absolute;
        right: 200px;
        top:270px;
        box-shadow: 10px 8px 9px rgba(158, 158, 158, 0.3);
    }
    &--leftBottom{
        border-bottom-left-radius: 0 !important;
        padding: 16px 10px;
        position: absolute;
        right: 650px;
        top:400px;
    }
    &--rightBottom{
        border-bottom-right-radius: 0 !important;
        padding: 18px 20px;
        position: absolute;
        right: 350px;
        top:500px;
    }
}


@media only screen and (max-width:1700px){
    .bannerFrame{
        &--leftTop{
            right: 545px;
        }
        &--rightTop{
            right: 160px;
        }
        &--leftBottom{
            right: 550px;
        }
        &--rightBottom{
            right: 320px;
        }
    }
}
@media only screen and (max-width:1440px){
    .bannerFrame{
        &--leftTop{
            right: 420px;
        }
        &--rightTop{
            right: 70px;
        }
        &--leftBottom{
            right: 400px;
        }
        &--rightBottom{
            right: 130px;
        }
    }
}
@media only screen and (max-width:1200px){
    .bannerFrame{
        &--leftTop{
            left: 100px;
            right: unset;
            top:380px;
            padding: 10px 14px;

        }
        &--rightTop{
            right: 200px;
            top:310px;
            padding: 14px 10px;

        }
        &--leftBottom{
            right: unset;
            top:600px;
            left: 150px;
            padding: 14px 10px;
        }
        &--rightBottom{
            top:650px;
            right: 230px;
            padding: 14px 16px;


        }
    }
}
@media only screen and (max-width:750px){
    .bannerFrame{
        &--leftTop{
            left: 100px;
            top:270px;

        }
        &--rightTop{
            right: 100px;
            top:300px;

        }
        &--leftBottom{
            top:620px;
            left: 80px;
        }
        &--rightBottom{
            top:600px;
            right: 110px;
        }
    }
}
@media only screen and (max-width:600px){
    .bannerFrame{
        &--leftTop{
            left: 70px;
            top:230px;
        }
        &--rightTop{
            right: 30px;
            top:290px;

        }
        &--leftBottom{
            top:520px;
            left: 50px;
        }
        &--rightBottom{
            top:520px;
            right: 60px;
        }
    }
}
@media only screen and (max-width:450px){
    .bannerFrame{
        &--leftTop{
            left: 50px;
        }
        &--rightTop{
            right: 10px;
            top:280px;
        }
        &--leftBottom{
            top:450px;
            left: 30px;
        }
        &--rightBottom{
            top:440px;
            right: 20px;
        }
    }
}
@media only screen and (max-width:390px){
    .bannerFrame{
        &--leftTop{
            left: 20px;
        }
        &--rightTop{
            right: 5px;
            top:290px;
        }
        &--leftBottom{
            top:400px;
            left: 20px;
        }
        &--rightBottom{
            top:400px;
            right: 2px;
        }
    }
}
@media only screen and (max-width:360px){
    .bannerFrame{
        &--leftTop{
            top:200px;
        }
        &--rightTop{
            top:270px;
        }
        &--leftBottom{
            top:365px;
            left: 8px;
        }
        &--rightBottom{
            right: 5px;
        }
    }
}

