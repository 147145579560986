.signInForm{
    display: flex;
    flex-direction: column;
    gap:20px;
    max-width:240px;
    width: 100%;

    &__forgotPass{
        font-size: 14px;
        line-height: 22px;
        margin-bottom: 4px;
        text-decoration: none;
        align-self: flex-end;
        color: #333333;
        cursor: pointer;
    }

    &__submit{
        align-self: center;
    }
}

