.profileLoans{
    width: 100%;
    margin-left: 70px;
    &__top{
        margin-bottom: 35px;
        display: flex;
        justify-content: space-between;
    }
    &__table{
        width: 100%;
        border-collapse: collapse;
        &__headingRow{
            width: 100%;
            text-align: center;
            color:#202020;  
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
            padding: 25px 0;
            border-bottom: 0.5px solid #CDD4D8;
        }
        &__row{
            width: 100%;
            padding: 25px 0;
            text-align: center;
            height: 74px;
            td{
                border-bottom: 0.5px solid #CDD4D8;
            }
        }
    }
    &__bottom{
        margin:30px 0 ;
    }
}
@media screen and (max-width: 1024px) {
    .loan-details{
        padding: 0 25px;
    }
    .profileLoans{
        margin:0 15px;
        &__table{
            &__headingRow{
                font-size: 14px;
            }
            &__row{
                height: 65px;
            }
        }
        &__rowMb{
            text-align: center;
            td{
                padding: 20px 0;
                text-align: left;
                div{
                    margin: 0;
                }
            }
            .name{
                font-size: 12px;
                font-weight: 400;
                line-height: 16px;
                color:#858585;
            }
        }
        &__bottom{
            display: flex !important;
            justify-content: center;
        }
    }
}
