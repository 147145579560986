
@font-face {
    font-family: 'Noto Sans Armenian';
    src: url('NotoSansArmenian-Regular.ttf') format('truetype');
    font-weight: normal;
}

@font-face {
    font-family: 'Noto Sans Armenian';
    src: url('NotoSansArmenian-Bold.ttf') format('truetype');
    font-weight: bold;
}

@font-face {
    font-family: 'Noto Sans Armenian';
    src: url('NotoSansArmenian-SemiBold.ttf') format('truetype');
    font-weight: 500;
}

@font-face {
    font-family: 'Noto Sans Armenian';
    src: url('NotoSansArmenian-Light.ttf') format('truetype');
    font-weight: 300;
}