.stepPopup {
  width: 100%;
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }

  &__top {
    max-width: 900px;
    margin: 0 auto;
    width: 100%;
    height: 140px;
    background: white;
    padding-top: 60px;
    flex-shrink: 0;
  }
}

.stepContent {
  flex: 1;
  overflow: hidden;
  height: 100%;
}

.stepPage {
  height: 100%;
  overflow: auto;
}

.popup-bottom {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 60px;
  border-top: 0.5px solid #CDD4D8;
  margin-top: 20px;
  background-color: white;
  z-index: 5;

  div {
    display: flex;
    gap: 10px;
  }

  &__button {
    position: relative;

    &--mob {
      position: relative;

      &::after {
        width: 12px;
        height: 12px;
        right: 9px;
        top: 8px;
      }
    }

    .stepPopup {
      width: 100%;
      height: 100%;
      overflow: auto;
      display: flex;
      flex-direction: column;
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
      &::-webkit-scrollbar {
        display: none;
      }

      &__top {
        max-width: 900px;
        margin: 0 auto;
        width: 100%;
        height: 140px;
        background: white;
        padding-top: 60px;
        flex-shrink: 0;
      }

      .stepContent {
        flex: 1;
        overflow: hidden;
        height: 100%;
      }

      .stepPage {
        height: 100%;
        overflow: auto;
      }

      .popup-bottom {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px 60px;
        border-top: 0.5px solid #CDD4D8;
        margin-top: 20px;
        background-color: white;
        z-index: 5;

        div {
          display: flex;
          gap: 10px;
        }

        &__button {
          position: relative;
        }

        &__button::after {
          content: "";
          background: url("../img/popups/rightArrow.svg");
          width: 12px;
          height: 12px;
          right: 30px;
          top: 18px;
          position: absolute;

        }

        &__buttonBack {
          position: relative;
          background-color: white;
        }

        &__buttonBack::before {
          content: "";
          background: url("../img/popups/leftArrow.svg");
          width: 12px;
          height: 12px;
          left: 2px;
          top: 18px;
          position: absolute;

        }
      }
    }
  }

  .mobileBtnGradient {
    position: relative;
    outline: none;
    border: none;
    outline: none;
    max-height: 40px;
    // padding: 40px;
    &::after {
      content: "";
      background: url("../img/popups/rightArrow.svg");
      width: 12px;
      height: 12px;
      right: 13px;
      top: 13px;
      position: absolute;
    }
  }

  .mobileBtnWhiteBlue {
    position: relative;
    outline: none;
    border: none;
    outline: none;
    max-height: 40px;
    // padding: 40px;
    &::after {
      content: "";
      background: url("../img/popups/leftArrow.svg");
      width: 12px;
      height: 12px;
      right: 13px;
      top: 13px;
      position: absolute;
    }
  }

  &__button::after {
    content: "";
    background: url("../img/popups/rightArrow.svg");
    width: 12px;
    height: 12px;
    right: 30px;
    top: 18px;
    position: absolute;

  }

  &__buttonBack {
    position: relative;
    background-color: white;
  }

  &__buttonBack::before {
    content: "";
    background: url("../img/popups/leftArrow.svg");
    width: 12px;
    height: 12px;
    left: 2px;
    top: 18px;
    position: absolute;

  }
}


@media only screen and (max-width: 1000px) {
  .stepPopup {
    &__top {
      max-width: 660px;
    }
  }
}

@media only screen and (max-width: 750px) {
  .stepPopup {
    overflow-y: auto;

    .stepContent {
      overflow-x: hidden;
      overflow-y: auto;
    }

    &__top {
      max-width: 600px;
      padding-left: 10px;
      padding-right: 10px;
    }
  }
}

@media only screen and (max-width: 500px) {
  .stepPopup {
    .stepContent {
      overflow-x: unset;
      overflow-y: auto;
    }

    &__top {
      overflow-x: scroll;
      margin: 0 10px;
    }
  }
  .popup-bottom {
    padding: 20px 0px 20px 50px;
  }
}
