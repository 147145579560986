.forgotPassword{
    display: flex;
    padding: 100px 60px;
    width: 100%;
    &-r{
        width: 55%;
    }
    &__left{
        display: flex;
        gap:10px;
        box-shadow: 0px 3px 13px rgba(0, 0, 0, 0.07);
        border-radius: 8px;
        height: 184px;
        width: 45%;

    &__text{
        max-width: 320px;
        padding-right: 15px;
    }
     & > img{
        width: 22px;
        height: 18px;
        margin: 7px;
        }
    &__prev{
        display: flex;
        align-items: center;
        gap: 14px;
        position: absolute;
        bottom: 25px;
        left: 32px;
        cursor: pointer;
    }    
    }
    &__right{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap:20px;
        width: 85%;
        &__title{
            color:#333333;
            font-weight: 500;
            font-size: 24px;
            line-height: 36px;
            text-align: center;
            margin-bottom: 38px;
        }
        
    }
    &__btn{
        margin-top: 28px;
    }
}

@media only screen and (max-width: 750px){
    .forgotPassword{
        flex-direction: column-reverse;
        align-items: center;justify-content: space-between;
        padding: 50px 30px;
        background-color: #F5F5F5;
        &__left{
            width: 100%;
            max-height:370px;
            height: 100%;
            padding: 20px;

        }
        &__right{
            width: 100%;
            padding: 0 20px;
            text-align: center;
            &__title{
                font-size: 18px;
                color:#858585;
            }
        }
        &-r{
            width: 100%;
            background-color: white;
            border-radius: 20px;
            padding: 50px 0;
            margin-bottom: 50px;
        }
    }
}