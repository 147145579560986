.smallPopup{
    padding: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 20px;
    &__title{
        font-size: 24px;
        font-weight: 500;
        line-height: 32px;
        color:#858585;
    }
    img{
        width: 130px;
        height: 118px;
    }
    &__content{

        position: relative;
        &__span{
            display: flex;
            gap:20px;
            input{
                opacity: 1 !important;
                width: 50px;
                height: 50px;
                padding: 13px;
                border: 1.5px solid #A9B5BC;
                border-radius: 8px;
                cursor: text;
                text-align: center;
                outline: none;
            }
        }
    }
    &__bottom{
        font-size: 12px;
        font-weight: 400;
        color:#858585;
        display: flex;
        flex-direction: column;
        gap:40px;
        a{
            color:#506B9B;
            font-weight: 500;
        }
        input{
            background-color: white;
            border: none;
            outline: none;
            color: #2B3B57;
            font-size: 14px;
            font-weight: 600;
            cursor: pointer;
            &:disabled{
                color:#CDD4D8;
            }
        }
    }
    &__buttons{
        display: flex;
        gap: 20px;
    }
    &__xstext{
        font-size: 18px;
        font-weight: 300;
        color:#202020;
    }
    &__smtext{
        font-size: 12px;
        font-weight: 400;
        color:rgba(133, 133, 133, 1)
    }
    &__mdtext{
        font-size: 18px;
        font-weight: 400;
        color:rgba(133, 133, 133, 1)
    }
    &__lgtext{
        font-size: 24px;
        font-weight: 500;
        line-height: 32px;
        color: #333333;
        &--blue{
            color:#2C4D86;
        }
    }
}

@media only screen and (max-width: 750px){
    .smallPopup{
        padding: 20px;
        img{
            width: 64px;
            height: 58px;
        }
        &__mdtext{
            font-size: 16px;
        }

    }
}