.mapSection{
    max-height: 643px;
    position: relative;
    height: 100%;
    z-index: 1;
    &__map{
        height: 100%;
    }
    &__layer{
        max-height: 525px;
        max-width: 382px;
        width: 100%;
        height: 100%;
        padding: 20px 20px 0 20px;
        position: absolute;
        left: 150px;
        top:60px;
        background: #F9F9FA;
        border-radius: 8px;
        overflow-y: scroll;
        // -ms-overflow-style: none;  /* IE and Edge */
        // scrollbar-width: none;  /* Firefox */
        margin-right: 10px;
        &::-webkit-scrollbar {
            width: 3px;
        }
        &::-webkit-scrollbar-track {
            box-shadow: inset 0 0 2px grey; 
            border-radius: 18px;
          }
        &::-webkit-scrollbar-thumb {
            background: #a60029; 
            border-radius: 10px;

        }
        &::-webkit-scrollbar-thumb:hover {
            background: #a60029; 
          }
        &__items{
            display: flex;
            flex-direction: column;
            gap:16px;
            //border-bottom:1px solid #EAEBEE;
            padding: 16px 0;
            h6{
                font-size: 18px;
                font-weight: 600;
                color:#858585;
                width: fit-content;
                padding-bottom: 10px;
                cursor: pointer;
                //border-bottom: 1.5px solid rgba(166, 0, 41, 1);
            }
            div{
                display: flex;
                gap:15px;
            }
            span{
                font-size: 16px;
                font-weight: 400;
                color:#858585;
            }
        }
    }
    .full-height{
        height: 350px !important;
        overflow-y: auto;
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
        &::-webkit-scrollbar {
          display: none;
        }
    }
    &__mobile{
        display: none;
        position: absolute;
        background-color: white;
        z-index: 2;
        top:90%;
        max-width: 330px;
        width: 100%;
        left: 50%;
        transform: translateX(-50%);
        border-radius: 8px;
        height: 50px;
        overflow: hidden;
        &__content{
            padding: 13px 19px;
            &__top{
                display: flex;
                justify-content: space-between;
                span{
                    font-size: 16px;
                    font-weight: 400;
                    color:rgba(51, 51, 51, 1)
                }
            }
        }
    }

    .mapPinText{
        font-size: 13px;
        font-weight: 600;
        color: #2b3b57;
        text-transform: uppercase;
        background-color: rgba(241,233,215,0.9);
        padding:0 2px;
    }
}

@media only screen and (max-width:750px){
    .mapSection{
        max-height: 258px;
        &__layer{
            display: none;
        }
        &__mobile{
            display: block;
        }
    }
}