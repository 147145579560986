.advantagesItem{
    display: flex;
    flex-direction: column;
    gap:16px;
    max-width: 340px;
    &__img{
        width: 100px;
        height: 100px;
    }

}

@media only screen and (max-width:1000px){
    .advantagesItem{
        align-items: center;
        text-align: center;
    }
}