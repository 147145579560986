.branchesPopup{
  padding: 20px 30px !important;
  gap:20px;
  &__text{
    color:#333333;
    font-weight: 600;
    font-size: 16px;
  }
}

.popupBranch{
  height:300px;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-thumb {
    background: #EAEDF3;
    border-radius: 8px;

  }
  &::-webkit-scrollbar-thumb:hover {
    background: #EAEDF3;
  }
  &__content{
   overflow: hidden;
  }
}
.buttonsSection{
  width: 100%;
  display: flex;
  justify-content: flex-end;
  &__buttons{
    display: flex;
    gap: 25px;
  }
}

.searchBox{
  position: relative;
  &__input{
    padding: 12px 45px 12px 12px;
    border: 1.5px solid #A9B5BC;
    border-radius: 8px;
    font-size: 16px;
    width: 100%;
  }

    &::after{
      content: "";
      position: absolute;
      background-image: url("../img/formSteps/search.svg");
      background-repeat: no-repeat;
      width: 16px;
      height: 16px;
      right: 25px;
      top:35%;
    }
}
.searchBox-icon{
  content: "";
  position: absolute;
  background-image: url("../img/formSteps/search.svg");
  width: 20px;
  height: 20px;
  right: 0;
}
