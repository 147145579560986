.homeCalc{
    padding: 20px 30px;
    background: rgba(238, 241, 251, 0.76);
    box-shadow: 0px 24px 14px -20px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    border-radius: 20px;
    width: 90%;
    margin: -100px auto 0 auto;
    height: 197px;
    ul{
        list-style: none;
        display: flex;
        gap:36px;
    }
    &__item{
        font-size: 20px;
        line-height: 28px;
        font-weight: 600;
        color:#848D9E;
        position: relative;
        padding-bottom: 15px;
        cursor: pointer;
        &.active{
            color:#2B3B57;
            &::before{
                content: "";
                width: 110%;
                height: 1px;
                background-color: #2B3B57;
                bottom: 0;
                left: -5px;
                position: absolute;
            }
        }
    }
    &__content{
        display: flex;
        align-items: center;
        gap:20px;
        padding: 30px;
        &__box {
            padding: 16px 18px 0 18px;
            background: #FFFFFF;
            border: 1.5px solid #EAEBEE;
            border-radius: 30px;
            gap:14px;
            max-width: 420px;
            width: 100%;
            position: relative;
            .insideInput{
                display: flex;
                gap: 15px;
                align-items: center;
                margin-bottom: 13px;
            }
            span {
                font-size: 20px;
                font-weight: 400;
                color:#A8AFBA;
            }
            div{
                width: 100% ;

            }
        }
    }
}

@media only screen and (max-width:850px){
    .homeCalc{
        width: 90%;
        height: 350px;
        margin: 35px auto;
        ul{
            padding-left: 0;
        }
        &__item{
            justify-content: center;
            font-size: 16px;
            &.active{
                color:#2B3B57;
            }
        }
        &__content{
            flex-direction: column;
            &__box{
                padding: 12px 16px;
                span{
                    font-size: 16px;
                }
            }
        }

    }
}