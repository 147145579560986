@import './vars';
.sidebar{
    // padding: 40px 40px 0px 60px;
    width: 24%;

    &__list{
        list-style: none;
        display: flex;
        flex-direction: column;
        padding: 40px 0 0 0 ;
        a{
            text-decoration: none;
        }
        &__item{
            padding-top: 15px;
            color:#2B3B57;
            font-size: 16px;
            line-height: 24px;
            position: relative;
            display: flex;
            align-items: center;
            &.active{
                font-weight: 500;
                color:#A60029
            }
        }
    }
}
.loans{
    cursor: pointer;
    &:hover{
        color: #A60029;
        font-weight: 500;
    }
    &:hover span{
        background-color: #A60029;
    }
    &.active{
        font-weight: 500;
        color:#A60029
    }
    &.active span{
        background-color:#A60029;
    }
    span{
        width: 18px;
        height: 13px;
        margin-right: 5px;
        -webkit-mask-image: url(../img//profile/creditCard.svg);
        mask-image: url(../img//profile/creditCard.svg);
        -webkit-mask-repeat: no-repeat;
        mask-repeat: no-repeat;
        -webkit-mask-size: contain;
        mask-size: contain;
        -webkit-mask-position: center;
        mask-position: center;
        background-color: #2B3B57;
    }
}

.info{
    cursor: pointer;
    &:hover{
        color: #A60029;
        font-weight: 500;
    }
    &:hover span{
        background-color: #A60029;
    }
    &.active{
        font-weight: 500;
        color:#A60029
    }
    &.active span{
        background-color:#A60029;
    }
    span{
        width: 18px;
        height: 18px;
        margin-right: 5px;
        -webkit-mask-image: url(../img//profile/user.svg);
        mask-image: url(../img//profile/user.svg);
        -webkit-mask-repeat: no-repeat;
        mask-repeat: no-repeat;
        -webkit-mask-size: contain;
        mask-size: contain;
        -webkit-mask-position: center;
        mask-position: center;
        background-color: #2B3B57;
    }
}

.messages{
    cursor: pointer;
    &:hover{
        color: #A60029;
        font-weight: 500;
    }
    &:hover span{
        background-color: #A60029;
    }
    &.active{
        font-weight: 500;
        color:#A60029
    }
    &.active span{
        background-color:#A60029;
    }
    span{
        width: 18px;
        height: 13px;
        margin-right: 5px;
        -webkit-mask-image: url(../img//profile/message.svg);
        mask-image: url(../img//profile/message.svg);
        -webkit-mask-repeat: no-repeat;
        mask-repeat: no-repeat;
        -webkit-mask-size: contain;
        mask-size: contain;
        -webkit-mask-position: center;
        mask-position: center;
        background-color: #2B3B57;
    }
}

.suggestions{
    cursor: pointer;
    &:hover{
        color: #A60029;
        font-weight: 500;
    }
    &:hover span{
        background-color: #A60029;
    }
    &.active{
        font-weight: 500;
        color:#A60029
    }
    &.active span{
        background-color:#A60029;
    }
    span{
        width: 20px;
        height: 20px;
        margin-right: 5px;
        -webkit-mask-image: url(../img//profile/suggestions.svg);
        mask-image: url(../img//profile/suggestions.svg);
        -webkit-mask-repeat: no-repeat;
        mask-repeat: no-repeat;
        -webkit-mask-size: contain;
        mask-size: contain;
        -webkit-mask-position: center;
        mask-position: center;
        background-color: #2B3B57;
    }
}

.exit{
    cursor: pointer;
    &:hover{
        color: #A60029;
        font-weight: 500;
    }
    &:hover span{
        background-color: #A60029;
    }
    &.active{
        font-weight: 500;
        color:#A60029
    }
    &.active span{
        background-color:#A60029;
    }
    span{
        width: 20px;
        height: 20px;
        margin-right: 5px;
        -webkit-mask-image: url(../img//profile/logout.svg);
        mask-image: url(../img//profile/logout.svg);
        -webkit-mask-repeat: no-repeat;
        mask-repeat: no-repeat;
        -webkit-mask-size: contain;
        mask-size: contain;
        -webkit-mask-position: center;
        mask-position: center;
        background-color: #2B3B57;
    }
}
@media screen and (max-width: 1024px) {
    .sidebar.open {
        display: block;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ffffff;
        width: 100%;
        padding-top: 200px;
        padding-left: 30px;

    }
}