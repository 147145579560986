.secondStep{
    display: flex;
    margin-top: 90px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    overflow: hidden;
    overflow-y: auto;
    flex: 1 1;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    &::-webkit-scrollbar {
        display: none;
    }
    //input{
    //    text-transform: uppercase;
    //}
}

.secondForm{
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 0 100px;
}
@media only screen and (max-width: 900px){
    .secondStep{
        overflow: auto;
        margin-top: 50px;
    }

}

@media only screen and (max-width:7500px){
    .secondForm{
        padding: 0 40px;
    }

}