.requestSent {
  display: flex;
  justify-content: center;
  text-align: center;
  padding-top: 30px;
  padding-bottom: 150px;
  position: relative;
  height: 100%;

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 28px;
    max-width: 540px;

    img {
      width: 130px;
      height: 118px;
    }

    &__title {
      font-size: 24px;
      line-height: 32px;
      font-weight: 500;
      color: #202020;
    }

    &__text {
      font-weight: 300;
      font-size: 20px;
      line-height: 28px;
      color: #858585;

      &--blue {
        font-weight: 400;
        color: #2B3B57;

      }
    }

    &__buttons {
      display: flex;
      align-items: center;
      gap: 20px;

      a {
        text-decoration: none;
      }

    }
  }

  &__textBox {
    display: flex;
    align-items: center;
    gap: 15px;
    max-width: 400px;
    max-height: 115px;
    padding: 10px;
    box-shadow: 0px 7px 14px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    text-align: justify;
    background-color: #FFFFFF;
    &--absolute{
      position: absolute;
      bottom: 40px;
      right: 10px;
    }
    img{
      width: 22px !important;
      height: 18px !important;
    }

    span {
      color: #858585
    }



  }
}

@media only screen and (max-width: 750px) {
  .requestSent {
    padding-bottom: 100px;
    &__content {
      max-width: 300px;
      gap: 20px;

      img {
        width: 64px;
        height: 57px;
      }

      &__title {
        font-size: 18px;
        color: #858585;
      }

      &__text {
        font-size: 14px;
        line-height: 22px;
      }
    }

    &__textBox {
      font-size: 14px;
      max-width: 335px;
      width: 100%;
      img{
        margin-right: 10px;
      }
      &--absolute{
        bottom: -45px;
        right: unset;
        left: 50%;
        transform: translateX(-50%)
      }
    }
  }
}
