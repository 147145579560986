.firstStep{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 80px;
    &__content{
        max-width: 490px;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        flex: 1 1;
        overflow: auto;
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
        &::-webkit-scrollbar {
            display: none;
        }
    }
    &__center{
        margin-bottom: 88px;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap:50px;
        
    }
    &__btnList{
        width: 100%;
        display: flex;
        align-items: center;
        gap: 20px;
        list-style: none;
        margin-bottom: 50px;
        padding-left: 0;
        color:#2B3B57;
        font-weight: 600;
        font-size: 14px;
        & > li{
            cursor: pointer;
        }
    }
    &__range{
        width: 100%;
        padding:0 11px;
        margin-right: 11px;
        &__title{
            font-size: 14px;
            color:#858585;
            font-weight: 400;
        }
        &__row{
            display: flex;
            justify-content: space-between;
            align-items: center;
            span{
                font-size: 18px;
                font-weight: 600;
                line-height: 16px;
                color:#858585;
            }
        }
    }
    &__bottom{
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 980px;
        width: 100%;
        background-color: #F9F9FA;
        padding-top: 50px;
        &__info{
            display: flex;
            justify-content: space-between;
            text-align: center;
            gap: 10px;
            width: 100%;
            & > *{
                padding: 5px 35px;
                width: 33.33%;
            }
        }
        &__text{
            margin-top: 40px;
            margin-bottom:  50px;
            text-align: center;
            max-width: 700px;
            text-transform: uppercase !important;
        }
    }
}

.firstStepForm{
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 0 100px;
}

@media screen and (max-width:1000px){
    .firstStep{
        padding-top: 30px;
        &__content{
            max-width: 460px;
        }
        &__bottom{
            max-width: 600px;
            &__info{
                & > *{
                    padding: 5px;
                }
            }
        }
    }
    .firstStepForm{
        padding: 0 30px;
    }
}
@media screen and (max-width:600px){
    .firstStep{
        &__btnList{
            justify-content: center;
            margin-bottom: 35px;
        }
        &__content{
            max-width: 330px;
            min-height: 300px;
            .firstStep__center{
                padding: 0 10px;
                margin-bottom: 40px;
                gap: 30px;
            }
        }
        &__bottom{
            max-width: 380px;
            padding-top: 10px;
            &__info{
                & > *{
                    padding: 5px;
                }
            }
            &__text{
                font-size: 12px !important;
                margin-top: 20px;
            }
        }
    }
    .firstStepForm{
        padding: 0 30px;
    }
}
@media screen and (max-width:390px){
    .firstStep {
        min-height: 600px;
    }
}
@media screen and (max-height:768px)and (max-width: 1400px){
    .firstStep{
        padding-top: 20px;
        &__btnList{
            margin-bottom: 20px;
        }
        &__center{
            gap: 20px;
        }
        &__bottom{
            padding-top: 30px;
            &__text{
                margin-top: 20px;
                margin-bottom: 30px;
            }
        }
    }

}