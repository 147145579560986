.signInPopup{
display: flex;
padding: 90px 60px;
width: 100%;

&__left{
    display: flex;
    flex-direction: column;
    gap:36px;
    max-width: 315px;
    width: 45%;
    text-align: justify;
}

&__right{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 55%;
    &__title{
        color:#333333;
        font-weight: 600;
        font-size: 20px;
        line-height: 28px;
        margin-bottom: 53px;
    }
}

}

@media only screen and (max-width:750px){
.signInPopup{
    flex-direction: column-reverse;
    align-items: center;
    padding: 60px 30px;
    gap:20px;
    background-color: #F5F5F5;
    &__left{
        width: 100%;
        gap:15px;
    }
    &__right{
        border-radius: 20px;
        background-color: white;
        padding-top: 50px;
        padding-bottom: 50px;
        width: 100%;
        &__title{
            margin-bottom: 35px;
        }
    }
}
}