@import "vars";

.header{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 40px 20px;
    
    &__menu{
        list-style: none;
        display: flex;
        align-items: center;
        gap:30px;
        color: #202020;
        font-size: 18px;
        a{
            text-decoration: none;
            &.active{
                color: #A60029;
                font-weight: 700;
            }
        }
        
        &__item{
            text-decoration: none;
            color: #202020;
            cursor: pointer;
  
        }

        &__btn{
            padding: 12px;
            background-color: #EAEDF3;
            border-radius: 24px;
            display: flex;
            gap:8px;
            

            img{
                border-radius: 50%;
                width: 24px;
                height: 24px;
                object-fit: cover;
            }

            span{
                font-size: 16px;
                font-weight: 600;
                color:#2B3B57;
                line-height: 24px;
            }
        }
    }
}

@media only screen and (max-width:600px){
    .header{
        padding: 20px;
        &__menu{
            font-size: 12px;
            gap:15px;
            &__btn{
                padding: 6px;
                span {
                    font-size: 12px;
                }
            }
            &__mbBtn{
                display: flex !important;
                flex-direction: column;
                align-items: center;
                img{
                    width: 15px;
                    height: 14px;
                }
            }
        }
        &__logo{
            width: 70px;
            height: 30px;
            &__img{
                width: 100%;
                height: 100%;
            }
        }

    }
}