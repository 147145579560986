.fourthStep{
    display: flex;
    flex-direction: column;
    padding: 60px 100px;
    padding-bottom: 0;
    max-width: 1200px;
    margin: 0 auto;
    height: 100%;
    &__content{
        display: flex;
        flex-direction: column;
        gap:40px;
        height: 100%;
        overflow: hidden;
    }
    &__title{
        font-size: 20px;
        color:#333333;
        font-weight: 400;
    }
    &__subtitle{
        font-weight: 600;
        color:#333333;
        font-size: 16px;
    }
    &__item{
        display: flex;
        flex-direction: column;
        gap:8px;
        width: 28.33%;
        &__label{
            font-size: 14px;
            font-weight: 400;
            color:#858585;
        }
        &__value{
            text-transform: uppercase;
        }

        &__filed{
            font-size: 20px;
            font-weight: 400;
            color:#202020;
        }
    }
}
@media only screen and (max-width:900px){
    .fourthStep{
        &__content{
            gap:20px;
            overflow-y: auto;
        }
    }
}

@media only screen and (max-width:750px){
    .fourthStep{
        padding: 30px 30px;

        &__title{
            font-size: 16px;
            font-weight: 600;
        }
        &__subtitle{
            color:#858585
        }
        &__item{
            width: 100%;
            background-color: #F9F9FA;
            padding: 10px;
        }
    }
}