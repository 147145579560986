.loanStatus{
    padding: 6px 12px;
    border-radius: 24px;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    max-height: 34px;
    margin: auto;

    &--PAYED{
        background-color: rgba(0, 159, 19, 0.1);
        color:#009F13;
        max-width: 125px !important;
    }
    &--DECLINED{
        background-color: rgba(255, 0, 0, 0.1);
        color:#FF0000;
        max-width: 93px;
    }
    &--NEW{
        background-color: rgba(255, 159, 64, 0.1);
        color:#FF9F40;
        max-width: 106px;
    }
    &--FINISH{
        background-color: rgba(133, 133, 133, 0.1);
        color:#858585;
        max-width: 89px;
    }


}