@import "vars";

.contact{
    width: 100%;
    &__map{
        width: 100%;
        height: 643px;
    }
    &__bottom{
        max-width: 885px;
        margin: 0 auto;
        display: flex;
        gap:140px;
        padding: 160px 0;
        &__form{
            max-width: 388px;
            width: 100%;
            form{
                width: 100%;
                display: flex;
                flex-direction: column;
                gap:20px
            }
            .contactTitle{
                font-size: 24px;
                font-weight: 500;
                color:#2B3B57;
                margin-bottom: 33px;
            }
            .contactSubtitle{
                font-size: 14px;
                font-weight: 400;
                color:#2B3B57;
                margin-bottom: 50px;
            }
            .contactMessage{
                max-width: 388px;
                width: 100%;
                background: white;
                border: 1.5px solid #A9B5BC;
                border-radius: 8px;
                padding: 12px 8px;
                color:#333333;
                font-size: 16px;
                outline: none !important;
                width: 100%;
                font-family: $fontRegular;
                &.error {
                    border-color: #FF0000;
                }
            }
        }
        &__info{
            display: flex;
            flex-direction: column;
            gap:35px;
            .infoItem{
                display: flex;
                gap:20px;
                font-size: 16px;
                font-weight: 400;
                color:#202020;
                a{
                    text-decoration:none;
                    font-size: innherit;
                    font-weight: innherit;
                    color:#202020;
                }
            }
        }
        .infoItem{
            &--mob{
                display: none;
            }
        }
    }
}

@media only screen and (max-width:1040px){
    .contact{
        &__bottom{
            flex-direction: column;
            align-items: center;
            padding: 60px 0;
            &__info{
                img{
                    max-width: 400px;
                }
            }
        }
    }
}

@media only screen and (max-width:750px){
    .contact{
        &__map{
            height: 258px;
        }
        &__bottom{
            &__form{
                .contactTitle{
                    margin-bottom: 20px;
                }
                .contactSubtitle{
                    margin-bottom: 30px;
                }
                max-width: 330px;
            }
            &__info{
              display: none;
        
            }
            .infoItem{
                &--mob{
                    display: flex;
                    justify-content: space-around;
                }
            }
        }
    }
}
@media screen and (max-width:500px)  {
    .contact{
        &__bottom{
            &__form{
                .contactMessage{
                    max-width: 290px;
                }
                form{
                    align-items: center;
                }
            }
        }
    }
}