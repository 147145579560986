.personalInfo{
    max-width: 860px;
    margin: 0 auto;
    height: 100%;
    display: flex;
    flex-direction: column;
    &--steps{
        max-width: 860px;
    }
    &__title{
        margin-bottom: 40px;
    }
    &__subtitle{
        margin-bottom: 36px;
    }
    &__fields{
        display: flex;
        flex-wrap: wrap;
        gap:30px 70px;
        margin-bottom: 35px;

        //input{
        //    text-transform: uppercase;
        //}

        &__passport{
            display: flex;
            align-items: center;
            color:#A9B5BC;
            font-size: 30px;
        }
    }
    &__bottom{
        display: flex;
        align-items:center;
        gap:24px;
        margin-bottom: 50px;
        a{
            text-decoration: none;
        }
    }
}

@media only screen and (max-width: 750px){
.personalInfo{
    &__fields{
        flex-direction: column;
        gap:15px;
    }
}
}