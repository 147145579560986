.footer{
    padding: 60px;
    background-color: #1E293D;
    color: #A9B5BC;
    font-size: 14px;

    &__title{
        color: #ffffff;
        font-weight: 600;
    }
    &__left_text{
        margin: 24px 0px;
        max-width: 465px;
        line-height: 22px;
        text-align: justify;
        a{
            text-decoration: none;
            color: #A9B5BC;

        }
    }
    &__divider{
        border: 1px solid #A9B5BC;
    }
    &__left_divider{
        max-width: 160px;
        margin-bottom: 24px ;
    }
    p{
        margin-bottom: 6px;
    }
    &__right_title{
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 20px;
    }
    &__info_row {
        margin-bottom: 20px;
        display: flex;
        gap:12px;
        color: #A9B5BC;
        font-size: 14px;
        text-decoration: none;
    }
    &__link{
        padding-top: 10px;
        a{
            padding-top: 10px;
            border-top: 1px solid #A9B5BC;
            color: #FFFFFF;
            font-size: 14px;
        }
    }
}

@media only screen and (max-width:1040px){

}