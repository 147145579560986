.terms{
    display: flex;
    justify-content: space-between;
    width: 100%;
    gap:30px;
    max-width: 1320px;
 
    &__list{
        max-width: 320px;
        width: 100%;
        position: fixed;
        ul{
            list-style: none;
            padding: 0;
            li{
                padding: 10px;
                cursor: pointer;
            }
        }
    }
    &__content{
        max-width: 964px;
        width: calc(100% - 355px);
        margin-left: 355px;
        margin-right: 20px;
        &__top{
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-bottom: 1px solid #CDD4D8;
            margin-bottom: 20px;
            text-align: right;
            .title{
                font-size: 20px;
                color:#858585;
            }
        }
    }
    
}
@media only screen and (max-width: 1000px){
    .terms{
        padding: 30px;
        &__list{
            display: none;
        }
        &__content{
            margin:0;
            width: 100%;
        }
    }
}
@media only screen and (max-width: 750px){
    .terms{
        &__content{
            &__top{
                border:none;
                flex-direction:column;
                align-items: flex-start;
                text-align: left;
                gap:20px;
                .title{
                    color:#333333;
                    font-size: 16px;
                }
                span{
                    font-size: 12px;
                }
            }
        }
    }
}