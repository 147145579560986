@import "vars";

.main-button{
    font-family: $fontRegular ;
    padding: 12px 24px;
    border-radius: 50px;
    width: fit-content;
    color:white;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    border: none;
    &:disabled{
        background-color: white;
        color: #CDD4D8;
    }
    &--mobile{
        padding: 20px;
        border-radius: 50%;
        background: linear-gradient(135.08deg, #A60029 39.28%, #FF7043 92.61%);
    }
    &--mobBlue{
        padding: 19px;
        border-radius: 50%;
        border:1px solid #2b3b57;
    }
    a{
        text-decoration: none;
        color:#2B3B57;
    }
    
    &--gradient{
        background: linear-gradient(135.08deg, #A60029 39.28%, #FF7043 92.61%);
    }
    &--blue{
        background-color: #2B3B57;
    }
    &--withBorder{
        color:#2B3B57;
        border: 1.5px solid #2B3B57;
    }
    &--lg{
        padding: 13px 24px;
    }
    &--md{
        padding: 12px 19px;
    }
    &--xl{
        padding: 19px 48px;
    }
    &--xl-md{
        padding: 13px 56px 13px 24px;
    }
    &--xxl{
        padding: 12px 62px;
    }
    &--fullwidth{
        width: 100%;
    }
}


@media only screen and (max-width:750px){
    .main-button{
        &--xl{
            padding: 16px 42px;
        }
    }
}

@media only screen and (max-width: 400px) {
    .main-button-mb{
        padding: 5px 10px;
        font-size: 12px;
    }

}