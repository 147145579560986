.homeBanner {
  width: 100%;
  display: flex;
  justify-content: space-between;

  &__info {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 45%;
    max-width: 520px;
    margin-top: 80px;
    margin-left: 30px;
    &__line {
      height: 10px;
      width: 0;
      animation: drawLine 1s;
      transition: all 1s ease;
    }

    &__text {
      font-size: 20px;
      line-height: 28px;
      font-weight: 400;
      color: #848D9E;
      margin-top: 50px;
    }


  }

  &__frame {
    display: flex;
    gap: 13px;

    img {
      width: 33px;
      height: 33px;
    }

    div {
      display: flex;
      flex-direction: column;
      max-width: 185px;
    }

    .text-lg {
      font-size: 24px;
      line-height: 32px;
      font-weight: 600;
      color: #2B3B57;
    }

    .text-sm {
      font-size: 16px;
      line-height: 24px;
      font-weight: 400;
      color: #858585;
    }

    .text-xs {
      font-size: 12px;
      line-height: 16px;
      font-weight: 400;
      color: #333333;
    }

    .stars {
      color: #FF9F40;
    }
  }

  &__img {
    width: 55%;
    position: relative;
  }
}

.line{
  height: 10px;
  align-self: flex-end;
  width: 70%;
  img{
    width: 100%;
    animation: drawLine 1s;
    transition: all 5s ease;

  }
}

@keyframes drawLine {
    0% {
        width: 10%;
    }
    100% {
      width: 100%;
    }
}

@media only screen and (max-width: 1200px) {
  .homeBanner {
    flex-direction: column;
    align-items: center;

    &__info {
      width: 100%;
      margin-top: 10px;
      margin-bottom: 30px;

      &__text {
        display: none;
      }
    }

    &__frame {
      .text-lg {
        font-size: 16px;
        line-height: 18px;

      }

      .text-sm {
        font-size: 14px;

      }

      .text-xs {
        font-size: 12px;

      }
    }

    &__info {
      &__img {
        width: 65%;
      }
    }
  }

}

@media only screen and (max-width: 750px) {
  .homeBanner {
    &__info {
      align-items: center;
      margin-left: 0;
    }
    &__img {
      width: 80%;
    }
  }
  .line{
    width: 40%;
    align-self: center;
  }

}

@media only screen and (max-width: 600px) {
  .homeBanner {
    &__img {
      width: 100%;
      padding: 0 20px;
    }

    &__info {
      max-width: 240px;
    }

    .line {
      width: 65%;
      align-self: center;
    }
  }
}















